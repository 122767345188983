import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export const CategoryCosmeticsPost = createAsyncThunk("CategoryCosmetics/post", async (body) => {
  return await axios.post(`${API_URL}/category_cosmetics`, body , { headers:{ token : cookies.get("token")}}).then((res) => res);
});
export const CategoryCosmeticsGet = createAsyncThunk("CategoryCosmetics/get", async () => {
  return await axios
    .get(`${API_URL}/category_cosmetics`)
    .then((response) => response.data);
});

export const CategoryCosmeticsDelete = createAsyncThunk("CategoryCosmetics/delete", async (id) => {
  return await axios
    .delete(`${API_URL}/category_cosmetics/${id}` , { headers:{ token : cookies.get("token")}})
    .then((response) => response.data);
});
export const CategoryCosmeticsPut = createAsyncThunk(
  "CategoryCosmetics/put",
  async ({ body, id }) => {
    return await axios
      .put(`${API_URL}/category_cosmetics/${id}`, body , { headers:{ token : cookies.get("token")}})
      .then((response) => console.log(response.data));
  }
);

export const UploadImage = createAsyncThunk("CategoryCosmetics/upload", async (e) => {
  const formData = new FormData();
  formData.append("file", e.target.files[0]);
  formData.append("upload_preset", "v0khd47o");
  try {
    return await axios
      .post("https://api.cloudinary.com/v1_1/dsdkp3672/upload", formData)
      .then((response) => response?.data.secure_url);
  } catch (error) {
    return error;
  }
});
const CategoryCosmeticsSlice = createSlice({
  name: "CategoryCosmetics",
  initialState: {
    CategoryCosmeticsGet: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
    CategoryCosmeticsPost: {
      Success: false,
      Error: false,
      loading: false,
    },
    CategoryCosmeticsDelete: {
      Success: false,
      Error: false,
      loading: false,
    },
    CategoryCosmeticsPut: {
      Error: false,
      Loading: false,
      Success: false,
    },
    uploadCategoryCosmetics: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
  },
  extraReducers: {
    // get
    [CategoryCosmeticsGet.pending]: (state, action) => {
      state.CategoryCosmeticsGet.loading = true;
    },
    [CategoryCosmeticsGet.fulfilled]: (state, action) => {
      state.CategoryCosmeticsGet.loading = false;
      state.CategoryCosmeticsGet.success = true;
      state.CategoryCosmeticsGet.data = action.payload;
      state.CategoryCosmeticsGet.error = false;
    },
    [CategoryCosmeticsGet.rejected]: (state, action) => {
      state.CategoryCosmeticsGet.loading = false;
      state.CategoryCosmeticsGet.error = true;
      state.CategoryCosmeticsGet.success = false;
    },
    // add
    [CategoryCosmeticsPost.pending]: (state, action) => {
      state.CategoryCosmeticsPost.loading = true;
    },
    [CategoryCosmeticsPost.fulfilled]: (state, action) => {
      state.CategoryCosmeticsPost.loading = false;
      state.CategoryCosmeticsPost.Success = true;
      state.CategoryCosmeticsPost.Error = false;
    },
    [CategoryCosmeticsPost.rejected]: (state, action) => {
      state.CategoryCosmeticsPost.loading = false;
      state.CategoryCosmeticsPost.Error = true;
      state.CategoryCosmeticsPost.Success = false;
    },
    // delete
    [CategoryCosmeticsDelete.pending]: (state, action) => {
      state.CategoryCosmeticsDelete.loadingDelete = true;
    },
    [CategoryCosmeticsDelete.fulfilled]: (state, action) => {
      state.CategoryCosmeticsDelete.loading = false;
      state.CategoryCosmeticsDelete.Success = true;
      state.CategoryCosmeticsDelete.Error = false;
    },
    [CategoryCosmeticsDelete.rejected]: (state, action) => {
      state.CategoryCosmeticsDelete.loading = false;
      state.CategoryCosmeticsDelete.Error = true;
      state.CategoryCosmeticsDelete.Success = false;
    },
    // put
    [CategoryCosmeticsPut.pending]: (state, action) => {
      state.CategoryCosmeticsPut.loading = true;
    },
    [CategoryCosmeticsPut.fulfilled]: (state, action) => {
      state.CategoryCosmeticsPut.Error = false;
      state.CategoryCosmeticsPut.Success = true;
      state.CategoryCosmeticsPut.Loading = false;
    },
    [CategoryCosmeticsPut.rejected]: (state, action) => {
      state.CategoryCosmeticsPut.Error = true;
      state.CategoryCosmeticsPut.Success = false;
      state.CategoryCosmeticsPut.Loading = false;
    },

    [UploadImage.pending]: (state, action) => {
      state.uploadCategoryCosmetics.Loading = true;
    },
    [UploadImage.fulfilled]: (state, action) => {
      state.uploadCategoryCosmetics.Error = false;
      state.uploadCategoryCosmetics.Success = true;
      state.uploadCategoryCosmetics.Loading = false;
      state.uploadCategoryCosmetics.data = action.payload;
      // console.log( );
    },
    [UploadImage.rejected]: (state, action) => {
      state.uploadCategoryCosmetics.Error = true;
      state.uploadCategoryCosmetics.Success = false;
      state.uploadCategoryCosmetics.Loading = false;
    },
  },
});

export const {} = CategoryCosmeticsSlice.actions;
export default CategoryCosmeticsSlice.reducer;
